<template>
    <div class="shop_page_main pt39" id="#top">
        <LayoutHeader :nickname="nickname" :noticeData="noticeData" />

        <LayoutSearch :searchDefaultData="searchDefaultData" :searchHotData="searchHotData" />

        <LayoutClass :menuData="menuData" :navData="navData" v-if="searchType !== 4" />

        <div :class="searchType === 4 ? 'shop_body_store' : 'shop_body'">
            <router-view />
        </div>

        <LayoutFooter />

        <div class="call_us" v-show="isCallUs">
            <img src="../../../assets/img/call_close.png" class="close" @click="isCallUs = false" />
            <div class="content">
                <div class="header">
                    <img src="../../../assets/img/call_phone.png" />
                    <span class="text">联系我们</span>
                </div>

                <div class="info">
                    <span class="time">周一至周五</span>
                    <span class="time">09:00 - 17:30</span>
                    <span class="phoneName">客服电话</span>
                    <span class="phoneNum">{{consumerPhone}}</span>
                </div>
            </div>
        </div>

        <div class="keep_us" v-show="isCallUs">
            <div class="image">
                <img src="../../../assets/img/code.png" />
            </div>
            <div class="text">微信公众号</div>
        </div>
    </div>
</template>

<script>
  import {getUserInfo, getGoodsMenu, getNav, getSearchHot, getSearchDefault} from '../../../api/public'
  import {getShopNotice} from '../../../api/shop/notice'
  import {mapState} from "vuex";
  import {consumerPhone} from '../../../utils/config'
  const LayoutHeader = () => import('./header')
  const LayoutFooter = () => import('./footer')
  const LayoutSearch = () => import('./search')
  const LayoutClass = () => import('./class')

  export default {
    name: "layout",
    data () {
      return {
        nickname: '',
        menuData: [],
        navData: [],
        searchHotData: [],
        searchDefaultData: '',
        isCallUs: true,
        consumerPhone,
        noticeData: [],
        searchType: 1
      }
    },
    computed: {
      ...mapState({
        status: state => state.user.userStatus, // status: 0 // 0 未实名 1 可用-未审核 2 禁用 3 注销 4 审核中 5 审核失败 6 审核通过 7 再次提交
      })
    },
    watch: {
      $route () {
        this.getRouter()
      }
    },
    created () {
      this.getGoodsMenu()
      this.getNav()
      this.getSearchHot()
      this.getSearchDefault()
      this.getShopNotice()
      if (localStorage.getItem('access_token_shop')) {
        this.getUserInfo()
        this.$store.commit('changeLoginType', true)
      } else {
        this.$store.commit('changeLoginType', false)
      }
      this.getRouter()
    },
    methods: {
      getGoodsMenu () {
        getGoodsMenu().then(res => {
          if (res.code === 0) {
            this.menuData = res.data
          }
        })
      },
      getNav () {
        getNav().then(res => {
          if (res.code === 0) {
            this.navData = res.data
          }
        })
      },
      getSearchHot () {
        getSearchHot().then(res => {
          if (res.code === 0) {
            this.searchHotData = res.data
          }
        })
      },
      getShopNotice () {
        getShopNotice({
          page: 1,
          pageSize: 10
        }).then(res => {
          if (res.code === 0) {
            this.noticeData = res.data.records
          }
        })
      },
      getSearchDefault () {
        getSearchDefault().then(res => {
          if (res.code === 0) {
            if (res.data) {
              let data = res.data.name.split(',')
              if (data.length > 0) {
                this.searchDefaultData = data[this.getRandomNumberByRange(data.length)]
              } else {
                this.searchDefaultData = ''
              }
            }
          }
        })
      },
      getRandomNumberByRange (end) {
        return Math.floor(Math.random() * end)
      },
      getUserInfo () {
        getUserInfo().then(res => {
          if (res.code === 0) {
            this.nickname = res.data.nickname
            this.$store.commit('changeUserStatus', res.data.status)
          }
        })
      },
      getRouter() {
        this.$store.commit('changeDefaultActive', this.$route.path);
        this.$store.commit('changeIsMenu', this.$route.meta.menu);
        this.searchType = this.$route.meta.searchType;

        if (this.$route.path === '/index') {
          this.$store.commit('changeIsShowClassOne', true)
        } else {
          this.$store.commit('changeIsShowClassOne', false)
        }
      }
    },
    components: {
      LayoutHeader,
      LayoutFooter,
      LayoutSearch,
      LayoutClass
    }
  }
</script>
